// Override default variables before the import
/*
$body-bg: white; // body color
$body-color: green; // text color
// buttons
$primary: #0074d9;
$danger: #ff4136;
$secondary: green;
$success: green;
$info: green;
$warning: green;
$light: green;
$dark: green;
*/

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';


.flexButtonContainer {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 576px) {
        justify-content: center;
        justify-items: center;
    }
}

.img-badge-custom {

    @media screen and (max-width: 576px) {
        display: inline-block;
        width: 100%;
        border: 1px;
    }
}

.custom-flex-xs {
    @media screen and (max-width: 576px) {
        display: block !important;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
}

.badge-home-discover {
    height: 50px;
    cursor: pointer;
    &.space-right {
        margin-right: 50px;
    }
}
.badge-home {
    //width: 170px !important;
    height: 50px;
    cursor: pointer;

    &.space-right {
        margin-right: 5px;
    }

    @media screen and (max-width: 992px) {
        height: 50px;
    }
    @media screen and (max-width: 576px) {
        margin: 0px;
        align-self: flex-start;
        width: 50%;
    }
}

.img-custom {
    @media screen and (max-width: 576px) {
        align-items: center;
        flex-direction: column;
        justify-items: center;

        margin-top: 10px;
    }
}

.top-ring {
    width: 33%;
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border-radius: 28px;

    @media screen and (max-width: 576px) {
        width: 75%;
        align-self: center;
    }
}
.image-home {
    width: 95%;
    max-width: 150px;
    background: #F9F9FA;
    border: 5px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.30);
    margin-top: 5px;
    margin-bottom: 5px;
    @media screen and (max-width: 576px) {
        max-width: 100%;
        width: 75%;
        margin-bottom: 15px;
    }

}

.hid-xs {
    @media screen and (max-width: 992px) {
        visibility: hidden;
        overflow: hidden;
        display: none;
    }
}

.custom-center {
    @media screen and (max-width: 576px) {
        display: inline;
        flex-direction: column;
        align-self: center;
    }
}

.play-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #0A5770;
    color: white;
    padding: 8px;
    border-radius: 15px;
    cursor: pointer;
    .textBtn {
        display: flex;
        align-self: center;
        color: white !important;
        font-size: 16px !important;
        margin: 0;
        padding: 0;
        margin-bottom: 1px;
    }
    span {
        color: white;
        img {
            width: 22px;
            margin-right: 5px;
        }
    }

}
.download-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #02C39A;
    color: white;
    padding: 8px;
    border-radius: 15px;
    margin-top: 5px;
    cursor: pointer;
    .textBtn {
        display: flex;
        align-self: center;
        color: white !important;
        font-size: 16px !important;
        margin: 0;
        padding: 0;
        margin-bottom: 1px;
    }
    span {
        color: white;
        img {
            height: 20px;
            margin-right: 5px;
        }
    }

}

.badge-src {
    margin-top: 10px;
    height: 66px;
    cursor: pointer;
    &.space-right {
        margin-right: 5px;
    }
    @media screen and (max-width: 992px) {
        height: 50px;
    }
}

.margin-a {
    margin-left: 10px;
}
#btn-mobile {
    width: 30px;
    height: 30px;
    z-index: 10;
    cursor: pointer;

    background: url('../assets/images/icon_menu.svg'); // icon_close.svg
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;

    background-color: white;
    border-radius: 5px;

    z-index: 100;


}
.header-section {
    //margin-top: 15px;
    color: #0A5770;
    border-top: 1px;
    border-bottom: 1px;
    border-left: 0px;
    border-right: 0px;
    border-style: solid;
    border-color: #E7F4FB;
    padding: 15px 0px 7px 0px;
    margin: 15px 0px 5px 0px;
    @media screen and (max-width: 576px) {
        //border: 1px solid red;
        margin-bottom: 0px;
    }
    h2 {
        font-weight: 700 !important;
        @media screen and (max-width: 576px) {
            font-size: 22px !important;
            text-align: center;
            margin-top: 10px;
            margin: 0px;
            padding-bottom: 5px;
        }
    }

}
.breadcrumbs {
    background-color: white !important;
    @media screen and (max-width: 576px) {
        margin-bottom: 0px;
    }
}
.item-ring {
    //border: 1px solid red;
    vertical-align: center;
    text-align: center;
    padding: 20px;
    justify-content: center;
    margin: 5px;

    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border-radius: 28px;

    padding: 30px 30px;

    @media screen and (max-width: 576px) {
        padding: 15px 15px;
    }

    h4 {
        @media screen and (max-width: 992px) {
            font-size: 17px;
        }
        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
    }

    span {
        @media screen and (max-width: 992px) {
            font-size: 14px;
        }
        @media screen and (max-width: 576px) {
            font-size: 16px;
        }
    }
    p{
        @media screen and (max-width: 992px) {
            font-size: 12px;
        }
        @media screen and (max-width: 576px) {
            font-size: 14px;
        }

    }

    @media screen and (max-width: 576px) {
        width: 75%;
        align-self: center;
        
    }
}
.flex-center {
    justify-content: center;
    align-items: center;
}
.image-sect-ringtones {
    width: 95%;
    max-width: 180px;
    background: #F9F9FA;
    border: 5px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.30);
    margin-top: 5px;
    margin-bottom: 5px;

    @media screen and (max-width: 576px) {
        max-width: 100%;
        width: 87%;
        //margin-bottom: 15px;
        border: 3px solid #ffffff;

        //border: 1px solid red;
    }

}

.imprint {
    color: #3E4352 !important;
    //font-weight: 500;
    &:hover {
        color: #02C39A !important
    }
}

.mobile-ring-list {
    display: flex;
    flex-direction: column;

    &.fake {
        //border: 1px solid red;
        min-height: 475px;
        align-self: center;
        justify-content: center;
        background-image: url('../assets/images/bg.png'); //url(https://source.unsplash.com/Q7PclNhVRI0); //pFqrYbhIAXs Q7PclNhVRI0
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0px;
        overflow: hidden;
        border: 1px solid white;
        @media screen and (max-width: 576px) {
            min-height: 0px;
        }
    }
    .blur {
        display: flex;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.2); // Make sure this color has an opacity of less than 1
        backdrop-filter: blur(5px); // This be the blur
        min-height: 475px;
        align-self: center;
        justify-content: center;
        padding: 15px;
        border-radius: 25px;
        overflow: hidden;
        @media screen and (max-width: 576px) {
            min-height: 0px;
        }
    }
    
    @media screen and (max-width: 576px) {
        flex-direction: row;
        align-items: center; // nel caso mettere center o flex-start
        width: 95%;
        //margin: 0px;
        //padding: 5px 1px;
        padding: 10px 5px;
        border: 1px solid rgba(0, 0, 0, 0.01);
        border-radius: 25px;

        :nth-child(1) {
            order: 2;
        }
        :nth-child(2) {
            order: 1;
        }
        :nth-child(3) {
            order: 3;
        }
    }
}
.space-10 {
    margin-top: 20px;
}

.cont-info {
    @media screen and (max-width: 576px) {
        flex-direction: row;
        flex-grow: 1;
        text-align: left;
        margin-right: 5px;
        min-width: 65%;
    }
    h4 {
        font-weight: 700;
        @media screen and (max-width: 576px) {
            font-size: 16px;
            font-weight: 700 !important;
            margin: 0px !important;
            padding: 0px !important;
        }
    }
    span {
        font-weight: 400 !important;
        @media screen and (max-width: 576px) {
            font-size: 13px;
            font-weight: 400 !important;
            margin: 0px !important;
            padding: 0px !important;
        }
    }
    p {
        font-weight: 100 !important;
        @media screen and (max-width: 576px) {
            font-size: 10px;
        }
    }
}
.contButtons-mo {
    display: none;
    :nth-child(1) {
        order: 1;
    }
    @media screen and (max-width: 576px) {
       display: block;
    }
}
.badge-src-fake {
    margin-top: 10px;
    height: 66px;
    cursor: pointer;

    &.space-right {
        margin-right: 5px;
    }

    @media screen and (max-width: 992px) {
        //height: 45px;
        width: 45%;
        margin-bottom: 15px;
    }
}
.cont-info-fake {
    text-align: center;
    color: black;

    @media screen and (max-width: 576px) {
        flex-direction: row;
        flex-grow: 1;
        text-align: center;
        margin-right: 5px;
        min-width: 65%;
    }

    h4 {
        font-weight: 700;

        @media screen and (max-width: 576px) {
            font-size: 22px;
            font-weight: 700 !important;
            margin: 0px !important;
            padding: 0px !important;
            margin-top: 15px !important;
        }
    }

    span {
        font-weight: 400 !important;

        @media screen and (max-width: 576px) {
            font-size: 13px;
            font-weight: 400 !important;
            margin: 0px !important;
            padding: 0px !important;
        }
    }

    p {
        font-weight: 100 !important;

        @media screen and (max-width: 576px) {
            font-size: 10px;
        }
    }
}
.contButtons-mo-fake {
    display: none;

    :nth-child(1) {
        order: 1;
    }

    @media screen and (max-width: 576px) {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        max-width: 100%;
    }
}
.fake-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 576px) {
        display: none;
        flex-grow: 1;

        :nth-child(1) {
            order: 1;
        }
    }
}
.contButtons-n {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 576px) {
        display: none;
        flex-grow: 1;
        :nth-child(1) {
            order: 1;
        }
    }
}
.contButtons {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.img-cont-m {
    @media screen and (max-width: 576px) {
        min-width: 35%;
        max-width: 135px;
    }
}

.space {
    margin-top: 120px;
    @media screen and (max-width: 576px) {
        margin-top: 60px;
    }
}

.no-ef {
    color: #3E4352;
    text-decoration: none;
    :hover {
        color: #3E4352 !important;
    }
}
.contButtons-detail {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 15px;
    width: 380px;
    @media screen and (max-width: 576px) {
        width: 90%;
        flex-direction: column;
    }
}
.play-btn-detail {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    background: #0A5770;
    border-radius: 17px;
    height: 55px;
    width: 100%;
    margin-right: 10px;
    cursor: pointer;
    @media screen and (max-width: 576px) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .textBtn {
        display: flex;
        align-self: center;
        color: white !important;
        font-size: 17px !important;
        margin: 0;
        padding: 0;
        margin-left: 5px;
    }
}
.download-btn-detail {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02C39A;
    border-radius: 17px;
    flex-grow: 1;
    height: 55px;
    width: 100%;
    cursor: pointer;
    .textBtn {
        display: flex;
        align-self: center;
        color: white !important;
        font-size: 17px !important;
        margin: 0;
        padding: 0;
        margin-left: 7px;
    }
}
.portfolio-info {
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 30px;
        font-weight: 700;
        @media screen and (max-width: 576px) {
            font-size: 23px;
        }
    }
    h2 {
        font-size: 25px;
        font-weight: 400;
        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
    }
    h5 {
        font-size: 20px;
        font-weight: 100;
        @media screen and (max-width: 576px) {
            font-size: 15px;
        }

    }
}
.image-detail {
    width: 100%;
    max-width: 300px;
    background: #F9F9FA;
    border: 5px solid #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.30);
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: 576px) {
        max-width: 100%;
        width: 90%;
        margin-bottom: 15px;
    }

}
.rounder {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-grow: 1;
    width: 100%;
    
    justify-content: space-evenly; //space-evenly;
    border-radius: 30px;
    @media screen and (max-width: 576px) {
        justify-content: space-between;
    }
}
.padding-mobile {
    width: 100%;
    a {
        text-decoration: none;
        color: #3E4352 !important;
        &:hover {
            color: #3E4352 !important;
        }
    }
}

.button-img {
    cursor: pointer;
    max-width: 50px;
    min-width: 50px;
    @media screen and (max-width: 576px) {
        max-width: 30px;
        min-width: 30px;
    }
}

.flex-center-404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    text-align: center;
    .text-cont {
        width: 40%;
        align-items: center;
        align-self: center;
        @media screen and (max-width: 900px) {
            width: 90%;
        }
    }
}
#lottie-logo {
    width: 300px;
    height: 300px;
    align-items: center;
    align-self: center;
    @media screen and (max-width: 576px) {
        margin-top: 100px;
        width: 270px;
        height: 270px;
    }
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 999;
}
.space-hero {
    margin-top: 10px;
}
.space-left {
    margin-right: 20px;
    margin-left: 0px !important;
    text-align: left;
    justify-content: left;
    align-self: flex-start;
    @media screen and (max-width: 576px) {
        margin: 0px;
        justify-content: center;
        text-align: center;
        align-self: center;
    }
}